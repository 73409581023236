import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/molecules/Layout";
import DateRangeSelect from "../../components/features/Dashboard/DateRangeSelect";
import MortgageAnalysis from "../../components/features/Dashboard/MortgageAnalysis";
import FinancialOverview from "../../components/features/Dashboard/FinancialOverview";
import clsx from "clsx";
import { SelectComponent } from "../../components/atoms/Select";
import { useForm } from "react-hook-form";
import { IDashboardFilters, IParameters } from "../../types/financialOverview";
import {
  generateCountyOptions,
  generateMortgageHolderOptions,
  getDateOnly,
  sortOptions,
} from "../../utils/financialOverview";
import { IOption } from "../../types/search";
import { getDropdownData } from "../../services/dashboard";
import UccSearch from "../../components/features/Dashboard/UccSearch";
import states from "../../utils/states";
import SearchButton from "../../components/features/Dashboard/SearchButton";
import usePrevious from "../../hooks/usePrevious";
import CustomMultiValue from "../../components/features/Dashboard/MultiValue";
import getCounties from "../../utils/counties";

interface DropdownDataResult {
  counties: string[];
  loan_institutions: string[];
}

const Dashboard = () => {
  const params = useParams();
  const navigate = useNavigate();

  if (!params.tab || !["ucc", "mortgage", "overview"].includes(params.tab)) {
    navigate(`/dashboard/overview`);
  }

  const handleTab = (section: "ucc" | "mortgage" | "overview") => {
    navigate(`/dashboard/${section}`);
  };

  const { control, watch, getValues } = useForm<IParameters>({
    defaultValues: {
      state: { label: "Kansas (KS)", value: "KS" },
    },
  });
  const [mortgageOptions, setMortgageOptions] = useState<IOption[]>([]);

  const [loadingMortgageOptions, setLoadingMortgageOptions] = useState(true);

  const today = new Date();
  const userTimezoneOffset = today.getTimezoneOffset() * 60000;
  today.setTime(today.getTime() - userTimezoneOffset);
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(today.getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(today.getFullYear() + 1, 0, 1)
  );
  const [timePeriod, setTimePeriod] = useState<string>("year");

  const [loanInstitutions, setLoanInstitutions] = useState<string[]>([]);
  const [counties, setCounties] = useState<string[]>([]);
  const stateValue = watch("state")?.value;
  const countyOptions = getCounties(stateValue || "");
  const prevState = usePrevious(stateValue);
  const formData = watch();
  const prevFormData = usePrevious(formData);

  const updateDropdowns = useCallback(
    (params: IDashboardFilters, callback?: () => void) => {
      getDropdownData(params).then((result: DropdownDataResult) => {
        if (result) {
          setMortgageOptions(
            generateMortgageHolderOptions(result?.loan_institutions || [])
          );
        }
        callback && callback();
      });
    },
    []
  );

  const handleSearch = (data: IParameters) => {
    const loanInstitutions =
      data.mortgageHolder
        ?.map((holder) => holder?.value?.trim())
        .filter((value): value is string => !!value) || [];
    setLoanInstitutions(loanInstitutions);
    const counties =
      data.county
        ?.map((county) => county.value?.trim())
        .filter((value): value is string => !!value) || [];
    setCounties(counties);
    if (startDate && endDate) {
      updateDropdowns({
        start_date: getDateOnly(startDate),
        end_date: getDateOnly(endDate),
        loan_institution: loanInstitutions,
        county: counties,
        state: data.state?.value as string,
      });
    }
  };

  const executeSearchIfChanged = () => {
    if (JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      handleSearch(formData);
    }
  };

  //first render
  useEffect(() => {
    if (startDate && endDate && params.tab === "overview") {
      updateDropdowns(
        {
          start_date: getDateOnly(startDate),
          end_date: getDateOnly(endDate),
          loan_institution: loanInstitutions,
          county: counties,
          state: getValues("state")?.value as string,
        },
        () => {
          setLoadingMortgageOptions(false);
        }
      );
    }
  }, [startDate, endDate]);

  //state
  useEffect(() => {
    if (prevState !== undefined && prevState !== stateValue) {
      updateDropdowns(
        {
          start_date: getDateOnly(startDate as Date),
          end_date: getDateOnly(endDate as Date),
          loan_institution: loanInstitutions,
          county: counties,
          state: getValues("state")?.value as string,
        },
        () => {
          setLoadingMortgageOptions(false);
        }
      );
    }
  }, [stateValue, prevState]);

  return (
    <Layout>
      <main
        className={clsx(
          "fill-available h-full p-8 bg-boundsPurple-400",
          "lg:pl-48  xl:ml-40 xl:p-8"
        )}
      >
        <div className={clsx("grid grid-cols-2")}>
          {params.tab === "overview" && (
            <div
              className={clsx(
                "gap-6",
                params.tab === "overview" ? "flex" : "hidden"
              )}
            >
              <div
                key={`search-select_date`}
                className="w-[160px] xl:w-[210px] 2xl:w-fit"
              >
                <DateRangeSelect
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setTimePeriod={setTimePeriod}
                />
              </div>
              <div key={`search-select_state`} className="w-[150px]">
                <SelectComponent
                  name={"state"}
                  control={control}
                  key={`input-parameter_state`}
                  label={"State"}
                  options={states}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  noOptionsMessage={() =>
                    "No options found for selected filters"
                  }
                  isClearable={false}
                  defaultValue={states[0]}
                />
              </div>
              <div
                key={`search-select_mortgage`}
                className="w-[160px] xl:w-[225px]"
              >
                <SelectComponent
                  name={"mortgageHolder"}
                  control={control}
                  key={`input-parameter_mortgageHolder`}
                  label={"Mortgage Holder"}
                  options={sortOptions(
                    mortgageOptions,
                    getValues("mortgageHolder") || [],
                    true
                  )}
                  placeholder={"Choose Mortgage Holders"}
                  isDisabled={loadingMortgageOptions}
                  isMulti={true}
                  noOptionsMessage={() =>
                    "No options found for selected filters"
                  }
                  isClearable={true}
                  onChange={(value, actionMeta) => {
                    if (actionMeta.action === "remove-value") {
                      const formData = watch();
                      handleSearch(formData);
                    }
                  }}
                  MenuList={(props) => (
                    <SearchButton
                      {...props}
                      onClick={() => {
                        const formData = watch();
                        handleSearch(formData);
                      }}
                    />
                  )}
                  onClear={() => {
                    const formData = watch();
                    handleSearch(formData);
                  }}
                  onMenuClose={executeSearchIfChanged}
                  MultiValue={(props) => <CustomMultiValue {...props} />}
                />
              </div>

              <div
                key={`search-select_county`}
                className="w-[160px] xl:w-[210px]"
              >
                <SelectComponent
                  name={"county"}
                  control={control}
                  key={`input-parameter_county`}
                  label={"County"}
                  options={sortOptions(
                    countyOptions,
                    getValues("county") || [],
                    true
                  )}
                  placeholder={"Choose counties"}
                  closeMenuOnSelect={false}
                  isMulti={true}
                  noOptionsMessage={() =>
                    "No options found for selected filters"
                  }
                  isClearable={true}
                  onChange={(value, actionMeta) => {
                    if (actionMeta.action === "remove-value") {
                      const formData = watch();
                      handleSearch(formData);
                    }
                  }}
                  MenuList={(props) => (
                    <SearchButton
                      {...props}
                      onClick={() => {
                        const formData = watch();
                        handleSearch(formData);
                      }}
                    />
                  )}
                  onClear={() => {
                    const formData = watch();
                    handleSearch(formData);
                  }}
                  onMenuClose={executeSearchIfChanged}
                  MultiValue={(props) => <CustomMultiValue {...props} />}
                />
              </div>
            </div>
          )}
          <div
            className={clsx(
              "flex gap-6 ml-auto",
              params.tab === "overview" ? "col-span-1" : "col-span-2"
            )}
          >
            <button
              className={clsx(
                "font-medium rounded  px-3 py-2 h-fit xl:w-[140px] xl:px-6 text-sm font-roboto",
                params.tab === "ucc"
                  ? "!bg-boundsYellow-50 text-black "
                  : "!bg-boundsGray-500 text-white"
              )}
              onClick={() => handleTab("ucc")}
            >
              UCC Search
            </button>
            <button
              className={clsx(
                "font-medium rounded  px-3 py-2 h-fit xl:w-[200px] xl:px-6 text-sm font-roboto",
                params.tab === "mortgage"
                  ? "!bg-boundsYellow-50 text-black "
                  : "!bg-boundsGray-500 text-white"
              )}
              onClick={() => handleTab("mortgage")}
            >
              Mortgage Rate Analysis
            </button>
            <button
              className={clsx(
                " px-3 xl:w-[168px] rounded leading-5 font-medium text-sm xl:px-6 py-2 h-fit font-roboto",
                params.tab === "overview"
                  ? "!bg-boundsYellow-50 text-black "
                  : "!bg-boundsGray-500 text-white"
              )}
              onClick={() => handleTab("overview")}
            >
              Financial Overview
            </button>
          </div>
        </div>
        <div className="my-7">
          {params.tab === "mortgage" ? (
            <MortgageAnalysis />
          ) : params.tab === "overview" ? (
            <FinancialOverview
              time={timePeriod}
              startDate={startDate}
              endDate={endDate}
              loan_institution={loanInstitutions}
              county={counties}
              state={getValues("state")?.value}
            />
          ) : (
            <UccSearch />
          )}
        </div>
      </main>
    </Layout>
  );
};

export default Dashboard;
